// library imports
import React from "react"
import { Image } from "react-bootstrap"

// image imports
import playStore from "../../../assets/images/footer/play-store.png"
import appleStore from "../../../assets/images/footer/apple-store.png"
import facebook from "../../../assets/images/footer/facebook.png"
import twitter from "../../../assets/images/footer/twitter.png"
import instagram from "../../../assets/images/footer/instagram.png"

export default function Footer() {
  return (
    <div>
      <h1 className="download-text text-center">Get Folksmedia App On</h1>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ position: "relative", zIndex: "1" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.folksmediaapp"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={playStore} width="125px" className="mt-2 ms-2" />
            </a>
          </div>
          <div>
            <a
              href="https://apps.apple.com/app/folksmedia/id1579558800"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={appleStore} width="125px" className="mt-2 ms-2" />
            </a>
          </div>
        </div>

        <div
          className=" d-flex justify-content-around align-items-center mt-4 pb-3"
          style={{ width: "150px" }}
        >
          <div>
            <a
              href="https://www.facebook.com/Folks-Media-110366754747935/?ref=pages_you_manage"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebook} />
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/folksmedia__"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={twitter} />
            </a>
          </div>
          <div>
            <a
              href=" https://www.instagram.com/__folks_media__/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={instagram} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
