// library imports
import React from "react"
import { Navbar } from "react-bootstrap"

// image imports
import logo from "../../../assets/images/logo/logo.png"

export default function Header() {
  return (
    <div className="sub-container pt-2 pb-2">
      <Navbar>
        <Navbar.Brand href="#home" className="sub-container ">
          <img
            alt=""
            src={logo}
            width="45"
            height="45"
            className="d-inline-block align-top p-0"
          />
          <span className="mobile-logo-text ms-2">Folksmedia</span>
        </Navbar.Brand>
      </Navbar>
    </div>
  )
}
