import styled from "styled-components"

const Wrapper = styled.section`
  .header-text {
    line-height: 60px;
    letter-spacing: 0.05em;
    margin-top: 129px !important;
    font-size: 40px;
    padding-bottom: 10px;
  }

  .custom-carousel-arrow {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    height: 2.7rem;
    width: 2.7rem;
    padding: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  .button-container {
    margin-top: 6rem;
  }

  @media only screen and (min-width: 1600px) {
  }
`

export default Wrapper

export const bgColor = {
  background:
    "linear-gradient(180deg, rgba(0, 0, 0, 0) 65.78%, #000000 100%), radial-gradient(133.31% 127.86% at 16.18% 29.95%, rgba(234, 58, 27, 0) 32.29%, #EA3A1B 100%), #1B1F24",
  paddingTop: "6rem",
}
