// import library
import React from "react"
import { Container, Row, Col, Image, Carousel } from "react-bootstrap"

// import components
import Slider from "./component/Slider"
import creativeBanner from "../../../assets/images/content/creatives-banner.png"
import CustomCarousel, { MainContainer } from "./component/Style"
import ContentData from "./component/Data"

export default function Content({ contentRef }) {
  return (
    <MainContainer>
      <Container
        className="first-slide-container"
        fluid={true}
        id="desktop-view"
        ref={contentRef}
      >
        <Container id="content">
          <Row>
            <div className="mt-3">
              <h1 className="primary-heading-text mt-5">
                One streaming guide for you and your family to find what to
                watch, fast!
              </h1>
            </div>
          </Row>

          <Row className="pt-5 ps-5">
            <CustomCarousel className="d-flex justify-content-center">
              <Col span={7}>
                <Carousel className="_background" indicators={false}>
                  {ContentData.map((item, idx) => {
                    return (
                      <Carousel.Item key={idx}>
                        <Col span={7} className="ps-5 ">
                          <span className="creative-logo-position">
                            <Image
                              src={item.image}
                              height="60px"
                              width="60px"
                            />
                          </span>
                          <h1 className="secondary-heading-text ps-5 fw-bold">
                            {item.heading}
                          </h1>
                          <div className="sub-container w-75">
                            <p className="paragraph-text ps-5">{item.title}</p>
                          </div>
                        </Col>
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </Col>

              <Col span={5}>
                <Image src={creativeBanner} />
              </Col>
            </CustomCarousel>
          </Row>
        </Container>

        <Container fluid={true}>
          <Slider />
        </Container>
      </Container>
    </MainContainer>
  )
}
