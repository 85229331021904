import styled from "styled-components"

const Wrapper = styled.div`
  padding-bottom: 4rem;
  .logo-position {
    position: relative;
    top: 122px;
    left: -105px;
  }

  @media only screen and (min-width: 1600px) {
  }
`

export default Wrapper
