import playStore from "../../../assets/images/footer/play-store.png"
import appleStore from "../../../assets/images/footer/apple-store.png"
import facebook from "../../../assets/images/footer/facebook.png"
import twitter from "../../../assets/images/footer/twitter.png"
import instagram from "../../../assets/images/footer/instagram.png"

const footerData_1 = [
  {
    title: "About Company",
    path: "/about-us",
  },
  {
    title: "Terms of Use",
    path: "/terms-of-use",
  },
  {
    title: "Whitepaper",
    path: "/whitepaper.pdf",
  },
  // {
  //   title: "Support Center",
  //   path: "",
  // },
  // {
  //   title: "Copyright",
  //   path: "",
  // },
  // {
  //   title: "Our Blogs",
  //   path: "",
  // },
]

const footerData_2 = [
  {
    title: "Return Policy",
    path: "",
  },
  {
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
]

const footerLogo = [
  {
    image: facebook,
    link: "https://www.facebook.com/Folks-Media-110366754747935/?ref=pages_you_manage",
  },
  {
    image: twitter,
    link: "https://twitter.com/folksmedia__",
  },
  {
    image: instagram,
    link: "https://www.instagram.com/__folks_media__/",
  },
]
const footerStore = [
  {
    image: playStore,
    link: " https://play.google.com/store/apps/details?id=com.folksmediaapp",
  },
  {
    image: appleStore,
    link: "https://apps.apple.com/app/folksmedia/id1579558800",
  },
]

export default footerData_1
export { footerData_2, footerLogo, footerStore }
