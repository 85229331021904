import styled from "styled-components"

const Wrapper = styled.section`
  padding: 4rem 0;

  .remote-title {
    width: 78%;
  }

  .moblieStore {
    margin-top: 6.5rem;
  }

  @media only screen and (min-width: 1600px) {
  }
`

export default Wrapper
