// library imports
import React from "react"
import { Col, Image, Row, Navbar, Container } from "react-bootstrap"

// components and data imports
import footerData_1, {
  footerData_2,
  footerLogo,
  footerStore,
} from "./component//Data"
import Wrapper from "./component/Style"

// image imports
import logo from "../../assets/images/logo/logo.png"

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer-background pb-2" id="desktop-view">
      <Container className="text-white">
        <Wrapper>
          <Row className="d-flex">
            <Col xs={12} md={2}>
              <Navbar style={{ marginTop: "-20px" }}>
                <Navbar.Brand href="#home" className="sub-container">
                  <img
                    alt=""
                    src={logo}
                    width="45"
                    height="45"
                    className="d-inline-block align-center p-0"
                  />
                  <span className="ms-2 fs-5 fw-bold logo-text">
                    Folksmedia
                  </span>
                </Navbar.Brand>
              </Navbar>

              <Row className="d-flex justify-content-start">
                <p className="fs-6 fw-bold">
                  Folksmedia ©{" "}
                  <span style={{ fontSize: "15px" }}>{currentYear} </span>
                </p>
                <Row className="d-flex justify-content-start">
                  <span className="fw-bold">Contact Us </span>
                  <p>
                    <a
                      href="mailto:contact@folksmedia.com"
                      target="_blank"
                      rel="noreferrer"
                      className="primary-text-color text-decoration-none lh-lg"
                    >
                      contact@folksmedia.com
                    </a>
                  </p>
                </Row>
              </Row>
            </Col>
            <Col
              xs={12}
              md={2}
              className=" d-flex  align-items-end flex-column"
            >
              <div>
                <h4 className="paragraph-text fw-bold text-uppercase">
                  About Us
                </h4>
                {footerData_1.map((item, idx) => (
                  <h3 key={idx} className="secondary-paragraph-text">
                    <a
                      href={item.path}
                      target="_blank"
                      className="footer-navigation"
                      rel="noreferrer"
                    >
                      {item.title}
                    </a>
                  </h3>
                ))}
              </div>
            </Col>
            <Col xs={12} md={2} className="ps-5">
              <h4 className="paragraph-text fw-bold text-uppercase">
                Our Policies
              </h4>
              {footerData_2.map((item, idx) => (
                <h3 key={idx} className="secondary-paragraph-text">
                  <a
                    href={item.path}
                    target="_blank"
                    className="footer-navigation"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </h3>
              ))}
            </Col>

            <Col xs={12} md={2}>
              <h4 className="paragraph-text fw-bold text-uppercase">Support</h4>

              <h3 className="secondary-paragraph-text">
                <a
                  href="mailto:support@folksmedia.com"
                  target="_blank"
                  rel="noreferrer"
                  className="primary-text-color text-decoration-none"
                >
                  support@folksmedia.com
                </a>
              </h3>
            </Col>
            <Col xs={12} md={4}>
              <Row className="sub-container">
                {footerStore.map((item, idx) => {
                  return (
                    <Col key={idx} md={5}>
                      <a target="_blank" href={item.link} rel="noreferrer">
                        <Image src={item.image} />
                      </a>
                    </Col>
                  )
                })}
                <Row className="sub-container mt-5">
                  {footerLogo.map((item, idx) => {
                    return (
                      <Col key={idx} md={2}>
                        <div className="rounded-circle social-logo sub-container">
                          <a target="_blank" href={item.link} rel="noreferrer">
                            <Image
                              src={item.image}
                              height="45px"
                              width="45px"
                            />
                          </a>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </div>
  )
}
