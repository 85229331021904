import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import img1 from "../../../../assets/images/content/slider/img-1.png"
import img2 from "../../../../assets/images/content/slider/img-2.png"
import img3 from "../../../../assets/images/content/slider/img-3.png"
import img4 from "../../../../assets/images/content/slider/img-4.png"
import img5 from "../../../../assets/images/content/slider/img-5.png"
import img6 from "../../../../assets/images/content/slider/img-6.png"
import img7 from "../../../../assets/images/content/slider/img-7.png"
import img8 from "../../../../assets/images/content/slider/img-8.jpg"
import img9 from "../../../../assets/images/content/slider/img-9.jpg"
import img10 from "../../../../assets/images/content/slider/img-10.jpg"
import { Container, Image } from "react-bootstrap"
import { AiOutlineDoubleRight } from "react-icons/ai"
import { AiOutlineDoubleLeft } from "react-icons/ai"

const TESTIMONIALS_DATA = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
  {
    image: img5,
  },
  {
    image: img6,
  },
  {
    image: img8,
  },
  {
    image: img9,
  },
  {
    image: img10,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img6,
  },
  {
    image: img1,
  },
  {
    image: img8,
  },
  {
    image: img7,
  },
]

var settings = {
  dots: false,
  infinite: false,
  speed: 700,
  slidesToShow: 8,
  slidesToScroll: 8,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 2200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
  ],
}

export default function Slides() {
  const sliderRef = useRef(null)
  return (
    <Container fluid={true} className="pb-5">
      <div className="d-flex justify-content-between align-items-center arrow-container p-2">
        <button
          className="media-button-left"
          onClick={() => sliderRef?.current?.slickPrev()}
        >
          <AiOutlineDoubleLeft />
        </button>
        <button
          className="media-button-right"
          onClick={() => sliderRef?.current?.slickNext()}
        >
          <AiOutlineDoubleRight />
        </button>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {TESTIMONIALS_DATA.map((item, idx) => {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              key={idx}
            >
              {idx === TESTIMONIALS_DATA.length - 1 && (
                <a
                  href="https://app.folksmedia.com/content/home"
                  target="_blank"
                  rel="noreferrer"
                  className="discover-more-btn"
                >
                  Discover more
                </a>
              )}
              <div>
                <Image
                  src={item.image}
                  height="242"
                  width="172"
                  style={{ borderRadius: "5px" }}
                />
              </div>
            </div>
          )
        })}
      </Slider>
    </Container>
  )
}
