import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import img_1 from "../../../assets/images/product/img-1.png"
import img_2 from "../../../assets/images/product/img-2.png"
import logo from "../../../assets/images/product/logo.png"
import Wrapper from "./component/Style"

export default function Product({ productRef }) {
  return (
    <Container
      className="bg-white"
      fluid={true}
      id="desktop-view"
      ref={productRef}
    >
      <Wrapper>
        <Container id="product" className="mt-3 pt-3 ">
          <Row className="mt-5">
            <h1 className="primary-heading-text">
              <span className="primary-text-color ">Experience shopping </span>
              Like never before
            </h1>
          </Row>

          <Row className="sub-container mt-3">
            <Col xs={12} md={6}>
              <Image src={img_1} />
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Image src={img_2} height="450" />
              </Row>

              <div className="ps-5  pe-5">
                <span className="logo-position">
                  <Image src={logo} />
                </span>
                <h1 className="secondary-heading-text fw-bold">
                  Shop with Folks App
                </h1>
                <p className="paragraph-text ">
                  Click-to-cart: Click the Shop icon to discover a personalized
                  shopping experience anytime
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Container>
  )
}
