import React, { useState } from "react"
import { AiOutlineArrowUp } from "react-icons/ai"

import styled from "styled-components"

const Button = styled.div`
  position: fixed;
  left: 95%;
  bottom: 35px;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  background-color: #ea3a1b;
  color: #ffffff;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        //   behavior: "auto",
      })
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible)
  }

  return (
    <Button style={{ display: visible ? "flex" : "none" }}>
      <AiOutlineArrowUp
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </Button>
  )
}

export default ScrollButton
