// library imports
import React from "react"
import { Container, Carousel, Image } from "react-bootstrap"

// components imports
import Footer from "./component/Footer"
import Header from "./component/Header"
import MobileBannerData from "./component/Data"
import Wrapper from "./component/Style"

// image imports
import backgroundImage from "../../assets/images/mobileView/bg-star-animation.gif"

export default function MobileBanner() {
  return (
    <div id="mobile-view">
      <Container fluid={true} className="mobile-container">
        <Header />
        <Wrapper>
          <Image
            src={backgroundImage}
            style={{ position: "absolute", right: "0px", top: "30%" }}
          />
          <Carousel interval={1500}>
            {MobileBannerData.map((item, idx) => {
              return (
                <Carousel.Item key={idx}>
                  <div className="sub-container flex-column">
                    <div className="title-container">
                      {item.title.map((item, idx) => {
                        return (
                          <h1 className={` title-text ${item.color}`} key={idx}>
                            {item.text}
                          </h1>
                        )
                      })}
                    </div>
                    <div className="sub-container">
                      <Image
                        src={item.img}
                        height="179px"
                        width="179px"
                        className="mt-4 mb-5"
                      />
                    </div>
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
          <Image
            src={backgroundImage}
            style={{ position: "absolute", right: "0px", top: "70%" }}
          />
          <Footer />
        </Wrapper>
      </Container>
    </div>
  )
}
