import playStore from "../../../../assets/images/footer/play-store.png"
import appleStore from "../../../../assets/images/footer/apple-store.png"

const Store = [
  {
    image: playStore,
    link: "https://play.google.com/store/apps/details?id=com.folksmediaapp",
  },
  {
    image: appleStore,
    link: "https://apps.apple.com/app/folksmedia/id1579558800",
  },
]

export default Store
