import bannerImg_1 from "../../../assets/images/banner/banner-1.png"
import bannerImg_2 from "../../../assets/images/banner/banner-2.png"
import bannerImg_3 from "../../../assets/images/banner/banner-3.png"
import bannerImg_4 from "../../../assets/images/banner/banner-4.png"
import bannerImg_5 from "../../../assets/images/banner/banner-5.png"

const MoblieBanner = [
  {
    title: [
      {
        text: "Discover",
        color: "primary-text-color",
      },
      {
        text: "Curated list of",
        color: "primary-text-color",
      },
      {
        text: "movies and TV shows",
        color: "text-white",
      },
    ],
    img: bannerImg_1,
    path: "#content",
  },
  {
    title: [
      {
        text: "Entertainments you love",
        color: "text-white",
      },
      {
        text: "now at your fingertips",
        color: "text-white",
      },
      {
        text: " Stream on any device",
        color: "primary-text-color",
      },
    ],
    img: bannerImg_5,
    path: "#deeplink",
  },
  {
    title: [
      {
        text: "Control your smart TV",
        color: "primary-text-color",
      },
      {
        text: "right from your phone",
        color: "text-white",
      },
      {
        text: "with Folksmedia app",
        color: "text-white",
      },
    ],
    img: bannerImg_2,
    path: "#remote",
  },

  {
    title: [
      {
        text: "Experience Shopping",
        color: "text-white",
      },
      {
        text: "with no interruption",
        color: "primary-text-color",
      },
      {
        text: "to your streaming",
        color: "primary-text-color",
      },
    ],
    img: bannerImg_3,
    path: "#product",
  },
  {
    title: [
      {
        text: "Get Rewarded!",
        color: "text-white",
      },
      {
        text: " Earn from Watching,",
        color: "primary-text-color",
      },
      {
        text: "Sharing & Shopping",
        color: "primary-text-color",
      },
    ],
    img: bannerImg_4,
    path: "#reward",
  },
]

export default MoblieBanner
