// library imports
import React from "react"
import { Navbar, Nav } from "react-bootstrap"

// components and style imports
import Wrapper from "./component/Style"
import { accountData } from "./component//Data"

// image imports
import logo from "../../assets/images/logo/logo.svg"

export default function Header({
  headerRef,
  bannerRef,
  contentRef,
  productRef,
  rewardRef,
  visibleSection,
  scrollTo,
}) {
  return (
    <Wrapper>
      <Navbar collapseOnSelect expand="lg" className="m-0 p-0" ref={headerRef}>
        <div id="desktop-view" className="navigation-bar flex-row">
          <Navbar className="p-0">
            <Navbar.Brand
              href="#home"
              className=" d-flex justify-content-around"
            >
              <img
                alt=""
                src={logo}
                width="45"
                height="45"
                className="p-0 m-0"
              />
              <span className="logo-text">Folksmedia</span>
            </Navbar.Brand>
          </Navbar>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className={`header_link me-5 ${
                  visibleSection === "Banner" ? "selected" : ""
                }`}
                onClick={() => {
                  scrollTo(bannerRef.current)
                }}
              >
                Home
              </Nav.Link>{" "}
              <Nav.Link
                className={`header_link me-5 ${
                  visibleSection === "Contents" ? "selected" : ""
                }`}
                onClick={() => {
                  scrollTo(contentRef.current)
                }}
              >
                Content
              </Nav.Link>{" "}
              <Nav.Link
                className={`header_link me-5 ${
                  visibleSection === "Shopping" ? "selected" : ""
                }`}
                onClick={() => {
                  scrollTo(productRef.current)
                }}
              >
                Shopping
              </Nav.Link>{" "}
              <Nav.Link
                className={`header_link me-5 ${
                  visibleSection === "Rewards" ? "selected" : ""
                }`}
                onClick={() => {
                  scrollTo(rewardRef.current)
                }}
              >
                Rewards
              </Nav.Link>
            </Nav>

            <Nav>
              {accountData.map((item, idx) => (
                <div className="sub-container me-4" key={idx}>
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noreferrer"
                    className={`text-white text-decoration-none sub-container pe-4 ps-4 pt-2 pb-2 fw-bold fs-6 btn ${item.color} `}
                  >
                    {item.text}
                  </a>
                </div>
              ))}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </Wrapper>
  )
}
