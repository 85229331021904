import tag_1 from "../../../../assets/images/content/tag_1.png"
import tag_2 from "../../../../assets/images/content/tag_2.png"
import tag_3 from "../../../../assets/images/content/tag_3.png"
import tag_4 from "../../../../assets/images/content/tag_4.png"

const Content = [
  {
    heading: "Now make movie nights easier for all!",
    title: `Whether you’re watching alone or with family/friends, Folksmedia App has you covered. With customized preferences for each user, you can combine profiles to find the best title recommendations for all. Just sit back and enjoy.`,
    image: tag_1,
  },
  {
    heading: "Share your thoughts and reviews with other Folks!",
    title: `Provide ratings and reviews of titles and share them with family and friends to make streaming entertainment easier and more personalized.`,
    image: tag_3,
  },
  {
    heading: "Rate titles for better recommendations!",
    title: `Your feedback helps Folkmedia generate the most spot-on recommendations for you.`,
    image: tag_4,
  },
  {
    heading: "Create watchlists and share them with friends and family!",
    title: `The streaming world can be overwhelming. Watchlists keep you organized, plus you can see what other Folks are watching, including friends, family, celebs, and influencers.`,
    image: tag_2,
  },
]

export default Content
