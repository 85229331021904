const Reward = [
  {
    heading: "Refer Folksmedia App to your friends",
    title: "You’ll both earn rewards when a referral is accepted.",
  },
  {
    heading: "Review what you watch",
    title: "Share your reviews of movies and shows to earn rewards.",
  },
  {
    heading: "Shopping with benefits",
    title: "Earn rewards with you shop with Folksmedia App.",
  },
  {
    heading: "Suggest improvements",
    title:
      "Your feedback is important to us. Send us suggestions and/or report bugs in Folksmedia App, and we’ll return the favor with rewards.",
  },
]

export default Reward
