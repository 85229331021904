import img_1 from "../../../../assets/images/deeplink/img-1.png"
import img_2 from "../../../../assets/images/deeplink/img-2.png"
import img_3 from "../../../../assets/images/deeplink/img-3.png"
import img_5 from "../../../../assets/images/deeplink/img-5.png"

const DeepLink = [
  {
    text: "Download Folksmedia app",
    image: img_1,
  },
  {
    text: "Create an account",
    image: img_2,
  },
  {
    text: "Connect to any device",
    image: img_3,
  },
  {
    text: "Stream and enjoy!",
    image: img_5,
  },
]

export default DeepLink
