import bannerImg_1 from "../../../../assets/images/banner/banner-1.png"
import bannerImg_2 from "../../../../assets/images/banner/banner-2.png"
import bannerImg_3 from "../../../../assets/images/banner/banner-3.png"
import bannerImg_4 from "../../../../assets/images/banner/banner-4.png"
import bannerImg_5 from "../../../../assets/images/banner/banner-5.png"

const Banner = [
  {
    title: [
      {
        text: "Welcome to Folksmedia!",
        color: "primary-text-color",
        fontSize: "fs-1",
      },
      {
        text: "With the Folksmedia App, you’ll discover and connect with the best movies and shows on popular streaming service, control your smart TV, shop, and earn rewards",
        color: "text-white",
        fontSize: "fs-4",
      },
    ],
    img: bannerImg_1,
    path: "#content",
  },
  {
    title: [
      {
        text: "Entertainment you love",
        color: "primary-text-color",
        fontSize: "fs-1",
      },
      {
        text: "Now at your fingertips",
        color: "text-white",
        fontSize: "fs-4",
      },
      {
        text: " Stream on any device",
        color: "text-white",
        fontSize: "fs-4",
      },
    ],
    img: bannerImg_5,
    path: "#deeplink",
  },
  {
    title: [
      {
        text: "Control your smart TV",
        color: "primary-text-color",
        fontSize: "fs-1",
      },
      {
        text: "right from your phone",
        color: "text-white",
        fontSize: "fs-4",
      },
      {
        text: "with Folksmedia app",
        color: "text-white",
        fontSize: "fs-4",
      },
    ],
    img: bannerImg_2,
    path: "#remote",
  },

  {
    title: [
      {
        text: "Experience Shopping",
        color: "primary-text-color",
        fontSize: "fs-1",
      },
      {
        text: "With no interruption",
        color: "text-white",
        fontSize: "fs-4",
      },
      {
        text: "to your streaming",
        color: "text-white",
        fontSize: "fs-4",
      },
    ],
    img: bannerImg_3,
    path: "#product",
  },
  {
    title: [
      {
        text: "Get Rewarded!",
        color: "primary-text-color",
        fontSize: "fs-1",
      },
      {
        text: " Earn from watching,",
        color: "text-white",
        fontSize: "fs-4",
      },
      {
        text: "sharing and shopping",
        color: "text-white",
        fontSize: "fs-4",
      },
    ],
    img: bannerImg_4,
    path: "#reward",
  },
]

export default Banner
