import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

import Wrapper from "./component/Style"
import MoblieStore from "./component/Data"

import img_1 from "../../../assets/images/remote/img-1.png"
import img_2 from "../../../assets/images/remote/img-2.png"

export default function Remote() {
  return (
    <Container className="third-slide-container" fluid={true} id="desktop-view">
      <Wrapper id="remote">
        <Container className="mt-1">
          <Row className="sub-container">
            <Col>
              <h4 className="primary-heading-text ">
                Universal <span className="primary-text-color"> Remote! </span>
              </h4>
              <p className="secondary-heading-text">
                Control any TV in your home with Folksmedia App
              </p>
            </Col>
            <Col className="d-flex justify-content-start align-items-center ps-5">
              <Image src={img_1} height="320px" width="510px" />
            </Col>
          </Row>

          <Row className="sub-container">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <Image src={img_2} height="580px" width="442px" />
            </Col>

            <Col className="pe-5 ps-4" xs={12} md={6}>
              <div>
                <h4 className="secondary-heading-text fw-bold ">
                  Can’t find the remote?
                </h4>

                <p className="paragraph-text remote-title">
                  Folksmedia App has a built-in remote to control your streaming
                  experience directly from your smartphone. Maybe grab a snack
                  instead of hunting for the remote. You can find loose change
                  between cushions later.
                </p>
              </div>

              <div className="moblieStore">
                <h1 className="download-text fw-bold ">
                  Folksmedia app has you covered.
                  <br /> Get started now!
                </h1>
                <div className="d-flex justify-content-start align-items-center mt-4 pb-5">
                  {MoblieStore.map((item, idx) => {
                    return (
                      <a key={idx} href={item.link} target="_blank">
                        <Image
                          src={item.image}
                          height="auto"
                          width="169px"
                          className="me-4"
                        />
                      </a>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Container>
  )
}
