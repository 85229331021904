const navigationData = [
  {
    text: "Home",
    path: "#home",
  },
  {
    text: "Content",
    path: "#content",
  },
  {
    text: "Shopping",
    path: "#shopping",
  },
  {
    text: "Rewards",
    path: "#rewards",
  },
]

const accountData = [
  {
    text: "Login",
    color: "btn-primary",
    path: "https://app.folksmedia.com/login",
  },
  {
    text: "Create Account",
    color: "btn-secondary",
    path: "https://app.folksmedia.com/register",
  },
]

export default navigationData

export { accountData }
