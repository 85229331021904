import styled from "styled-components"

const Wrapper = styled.section`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  background: #1a2228;

  .logo-text {
    color: #ea3a1b !important;
    width: 225px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.3rem !important;
    line-height: 37px;
    text-transform: capitalize;
  }

  .navigation-bar {
    width: 85vw;
    display: flex;
    margin: 0 auto;
  }

  @media only screen and (min-width: 2000px) {
    .navigation-bar {
      padding: 0 9rem;
    }
  }
`

export default Wrapper
