// imports library
import React, { useState, useRef } from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Slide } from "react-slideshow-image"
import { FiChevronLeft } from "react-icons/fi"
import { FiChevronRight } from "react-icons/fi"

// imports components
import CustomButton from "../../../components/CustomButton"
import Wrapper, { bgColor } from "./component/Style"
import BannerData from "./component/Data"

export default function Banner({ bannerRef }) {
  const slideRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  const back = () => {
    if (currentSlide === 0) {
      setCurrentSlide(BannerData.length - 1)
    } else {
      setCurrentSlide(slideRef.current.state.index - 1)
    }
    slideRef.current.goBack()
  }

  const next = () => {
    if (currentSlide >= BannerData.length - 1) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(slideRef.current.state.index + 1)
    }
    slideRef.current.goNext()
  }

  const properties = {
    autoplay: false,
    transitionDuration: 500,
    infinite: true,

    prevArrow: (
      <div>
        <FiChevronLeft className="custom-carousel-arrow" onClick={back} />
      </div>
    ),
    nextArrow: (
      <div>
        <FiChevronRight className="custom-carousel-arrow" onClick={next} />
      </div>
    ),
  }

  return (
    <Container fluid={true} className="p-0" id="home" ref={bannerRef}>
      <div style={bgColor} id="desktop-view">
        <Wrapper className="h-75 mt-5">
          <Container className="mt-5">
            <Slide ref={slideRef} {...properties}>
              {BannerData.map((item, idx) => {
                return (
                  <div key={idx}>
                    <Row>
                      <Col xs={12} md={6} className="sub-container">
                        <Col className="ps-5 w-100">
                          {item.title.map((item, idx) => {
                            return (
                              <div className={item.color} key={idx}>
                                <span className={item?.fontSize}>
                                  {item.text}
                                </span>
                              </div>
                            )
                          })}
                        </Col>
                      </Col>
                      <Col xs={12} md={6} className="sub-container">
                        <Image src={item.img} />
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Slide>

            <Row>
              <Col md={12} className="sub-container button-container pb-5 pt-5">
                <CustomButton path={BannerData[currentSlide]?.path} />
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </div>
    </Container>
  )
}
