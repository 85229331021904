import styled from "styled-components"

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;

  @media only screen and (min-width: 1600px) {
  }
`

export default Wrapper
