import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import MobileBanner from "../sections/mobileView/MobileBanner"
import Footer from "../components/footer/Footer"
import ScrollButton from "../components/ScrollButton"
import Banner from "../sections/desktopView/banner/Banner"
import Content from "../sections/desktopView/content/Content"

import DeepLink from "../sections/desktopView/deeplink/DeepLink"
import Remote from "../sections/desktopView/remote/Remote"
import Product from "../sections/desktopView/product/Product"
import Rewards from "../sections/desktopView/rewards/Rewards"
import Header from "../components/header/Header"

import "react-slideshow-image/dist/styles.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  })
}

const IndexPage = () => {
  const [visibleSection, setVisibleSection] = useState()

  const headerRef = useRef(null)
  const bannerRef = useRef(null)
  const contentRef = useRef(null)
  const productRef = useRef(null)
  const rewardRef = useRef(null)

  const sectionRefs = [
    { section: "Banner", ref: bannerRef },
    { section: "Contents", ref: contentRef },
    { section: "Shopping", ref: productRef },
    { section: "Rewards", ref: rewardRef },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current)
      const scrollPosition = window.scrollY + headerHeight

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        }
      })

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section)
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined)
      }
    }

    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [visibleSection, sectionRefs])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>

      <Layout>
        <MobileBanner />
      </Layout>

      <Layout>
        <Header
          headerRef={headerRef}
          bannerRef={bannerRef}
          contentRef={contentRef}
          productRef={productRef}
          rewardRef={rewardRef}
          visibleSection={visibleSection}
          scrollTo={scrollTo}
        />

        <Banner bannerRef={bannerRef} />
        <div ref={contentRef}>
          <Content />
          <DeepLink />
          <Remote />
        </div>
        <Product productRef={productRef} />
        <Rewards rewardRef={rewardRef} />
        <Footer />
        <ScrollButton />
      </Layout>
    </>
  )
}

export default IndexPage
