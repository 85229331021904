import styled from "styled-components"

const Wrapper = styled.section`
  /* carousel arrow styling */
  .carousel-indicators [data-bs-target] {
    height: 10px !important;
    width: 10px !important;
    border-radius: 100%;
  }
`

export default Wrapper
