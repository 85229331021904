// library imports
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

// image imports
import banner from "../../../assets/images/reward/banner.png"

// components imports
import Wrapper from "./component/Style"
import RewardData from "./component/Data"

export default function Rewards({ rewardRef }) {
  return (
    <Container
      className="second-slide-container"
      fluid={true}
      id="desktop-view"
      ref={rewardRef}
    >
      <Container>
        <Wrapper id="reward">
          <Row>
            <Col>
              <Row>
                <Col>
                  <h4 className="primary-heading-text text-white">
                    Earn Valuable
                    <span className="primary-text-color"> Rewards! </span>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="ps-5 mt-5">
                  {RewardData.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <h4 className="secondary-heading-text text-white fw-bold mt-5 mb-2">
                          {item.heading}
                        </h4>
                        <p className="paragraph-text text-white ">
                          {item.title}
                        </p>
                      </div>
                    )
                  })}
                </Col>
              </Row>
            </Col>
            <Col className="sub-container pt-5">
              <Image src={banner} height="340px" width="465px" />
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </Container>
  )
}
