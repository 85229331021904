// library imports
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

// component imports
import NavigationData from "./component/Data"
import Wrapper from "./component/Style"

// image imports
import banner from "../../../assets/images/deeplink/banner.png"

export default function DeepLink() {
  return (
    <Container
      className="second-slide-container"
      fluid={true}
      id="desktop-view"
    >
      <Wrapper id="deeplink" className="mt-2 pt-3">
        <Row>
          <div className="mt-5 pt-1 pb-5">
            <h1 className="primary-heading-text text-white text-center">
              Watch your favorite movies and shows on
              <span className="primary-text-color"> Any Device</span>
            </h1>
          </div>
        </Row>

        <Row className="sub-container mt-3">
          <Col span={6} className="sub-container">
            <Image src={banner} />
          </Col>
        </Row>

        <Row>
          <Col span={12} className="text-center mt-4  ">
            <h1 className="text-center text-white primary-heading-text pb-5">
              Get started now!
            </h1>
          </Col>
        </Row>
        <Row className="sub-container">
          {NavigationData.map((item, idx) => {
            return (
              <Col key={idx} className="sub-container flex-column">
                <div className="sub-container creative-logo">
                  <Image src={item.image} />
                </div>
                <h5 className=" creative-logo-text mt-4">
                  <span>{item.text}</span>
                </h5>
              </Col>
            )
          })}
        </Row>
      </Wrapper>
    </Container>
  )
}
