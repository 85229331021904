import React from "react"
import styled from "styled-components"

const ButtonContainer = styled.div`
  .wrapper {
    display: flex;
    justify-content: center;
  }

  .cta {
    display: flex;
    padding: 8px 30px;
    align-items: center;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    color: white;
    background: #ea3a1b;
    transition: 1s;
    /* box-shadow: 6px 6px 0 black; */
    /* transform: skewX(-15deg); */
    /* border-radius: 45px; */
    border-radius: 5px;
  }

  .cta:focus {
    outline: none;
  }

  .cta:hover {
    transition: 0.5s;
    box-shadow: 16px 6px 0 #ffc09d;
  }

  .cta span:nth-child(2) {
    transition: 0.5s;
    margin-top: 0px;
  }

  .cta:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 5px;
  }

  span {
    /* transform: skewX(15deg); */
  }

  span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }

  /**************SVG****************/

  path.one {
    transition: 0.4s;
    transform: translateY(2%);
  }

  path.two {
    transition: 0.5s;
    transform: translateY(2%);
  }
  path.three {
    transition: 0.5s;
    transform: translateY(2%);
  }

  .cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
  }

  .cta:hover path.one {
    transform: translateY(20%);
    animation: color_anim 1s infinite 0.6s;
  }

  .cta:hover path.two {
    transform: translateY(40%);
    animation: color_anim 1s infinite 0.4s;
  }

  /* SVG animations */

  @keyframes color_anim {
    0% {
      fill: white;
    }
    50% {
      fill: #ea3a1b;
    }
    100% {
      fill: white;
    }
  }
`

export default function CustomButton({ path }) {
  return (
    <ButtonContainer>
      <div className="wrapper">
        <a className="cta" href={path}>
          <span>Learn More</span>
          <span>
            <svg width="43px" height="50px" viewBox="0 0 55 43">
              <g
                id="arrow"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <path
                  className="one"
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                  fill="#FFFFFF"
                ></path>
                <path
                  className="two"
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                  fill="#FFFFFF"
                ></path>
                <path
                  className="three"
                  d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                  fill="#FFFFFF"
                ></path>
              </g>
            </svg>
          </span>
        </a>
      </div>
    </ButtonContainer>
  )
}
