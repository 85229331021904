import styled from "styled-components"

const Wrapper = styled.section`
  padding: 2rem 0 1rem 0;

  .social-logo {
    background-color: #000000;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
  }

  .footer-navigation {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      color: #ea3a1b;
      transition: 0.25s ease-in-out;
    }
  }

  @media only screen and (min-width: 1600px) {
  }
`

export default Wrapper
