import styled from "styled-components"

const CustomCarousel = styled.div`
  ._background {
    width: 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    visibility: hidden;
  }
`

export default CustomCarousel

export const MainContainer = styled.div``
